import type { PostSalesProject } from "rdx/modules/projects/types/projects/post-sales";
import { HomeownerInfoPropsT } from "components/Tabs/Project/Widgets/HomeownerHeader/types";

const formatProjectHeaderData = (projectData: PostSalesProject): HomeownerInfoPropsT => {
  const { lead } = projectData;
  return {
    firstName: lead.firstName,
    lastName: lead.lastName,
    email: lead.email,
    phone: lead.phone,
    leadId: lead.id,
    projectId: projectData.id,
    proposalUrl: projectData?.proposalUrl,
    visionProposal: projectData.visionProposal,
    address: {
      street: lead.address.street,
      city: lead.address.city,
      state: lead.address.state,
      zip: lead.address.zip,
    },
    transparencyInfo: {
      milestone: projectData.milestone,
      stage: projectData.stage,
      type: projectData.projectType,
      passedStages: projectData.projectProgress,
    },
    projectUsers: [
      { avatar: projectData.agent?.avatar?.thumb, role: "S" },
      { avatar: projectData.mentor?.avatar?.thumb, role: "T3" },
      { avatar: projectData.coordinator?.avatar?.thumb, role: "PM" },
      { avatar: projectData.ambassador?.avatar?.thumb, role: "A" },
    ],
    counts: {
      notes:
        (projectData.projectAtAGlance?.projectNotifications?.length || 0) +
        (projectData.powurOsData?.notes?.length || 0),
      tickets: projectData.ticketsCount,
    },
    proposalNonrequestableReason: projectData.proposalNonrequestableReason,
    roofingNonRequestableReason: projectData.roofingNonRequestableReason,
    crmActivities: projectData.crmActivities,
    upcomingStageNames: projectData.upcomingStageNames,
    projectProgress: projectData.projectProgress,
    closeAt: projectData.closeAt,
    closeReasonId: projectData.closeReasonId,
    isValidServiceArea: projectData.isValidServiceArea,
  };
};

export default formatProjectHeaderData;
