export enum TeamUserTypeEnum {
  ORG_MANAGER = "enterprise_manager",
  ORG_SUPPORT = "enterprise_support",
  LEAD_GEN = "enterprise_lead_generator",
  SELLER = "powur_energy",
  ORG_SELLER = "enterprise_consultant",
  ENT_OWNER = "powur_enterprise_pro",
  POWUR_PRO = "powur_enterprise",
  PREVIEW_USER = "preview_user",
}

export enum TeamTypeEnum {
  ORG_TEAM = "org_team",
  SELLER_TEAM = "seller_team",
}
