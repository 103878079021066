import { combineReducers } from "redux";
import leadsReducers from "rdx/modules/leads/reducers";
import activeRequestsReducers from "rdx/modules/activeRequests/reducers";
import appReducers from "rdx/modules/app/reducers";
import messagesReducers from "rdx/modules/messages/reducers";
import projectsReducers from "rdx/modules/projects/reducers";
import inboxReducers from "rdx/modules/tickets/reducers";
import usersReducers from "rdx/modules/users/reducers";
import teamReducers from "rdx/modules/team/reducers";
import rbacReducers from "rdx/modules/rbac/reducers";
import adminProjectsReducers from "rdx/modules/adminProjects/reducers";
import workflowsReducers from "rdx/modules/workflows/reducers";
import ambassadorReducers from "rdx/modules/ambassador/reducers";
import profileReducers from "rdx/modules/profile/reducers";
import reportsReducers from "rdx/modules/reports/reducers";
import admindashboardReducers from "rdx/modules/adminDashboard/reducers";
import consultantDashboardReducers from "rdx/modules/consultantDashboard/reducers";
import settingsReducers from "rdx/modules/settings/reducers";
import adminNoticesReducers from "rdx/modules/adminNotices/reducers";
import adminSmsReducers from "rdx/modules/adminSms/reducers";
import earningsReducers from "rdx/modules/earnings/reducers";
import registrationReducers from "rdx/modules/registration/reducers";
import financialsReducers from "rdx/modules/financials/reducers";
import messagingMetricsReducers from "rdx/modules/adminReports/reducers";
import adminProposalsReducers from "rdx/modules/adminProposals/reducers";
import mentorratingsReducers from "rdx/modules/mentorRatings/reducers";
import workSchedulesReducers from "rdx/modules/workSchedules/reducers";
import bypassPaywallReducers from "rdx/modules/bypassPaywall/reducers";
import careerListingsReducers from "rdx/modules/careerListings/reducers";
import stateLicensesReducers from "rdx/modules/stateLicenses/reducers";
import authReducers from "rdx/modules/auth/reducers";
import filterSetsReducers from "rdx/modules/filterSets/reducers";
import compensationReducers from "rdx/modules/compensation/reducers";
import platformReducers from "rdx/modules/platform/reducers";
import enterpriseProjectsReducers from "rdx/modules/enterpriseProjects/reducers";
import enterpriseOrgReducers from "rdx/modules/enterpriseOrg/reducers";
import enterpriseLeadsReducers from "rdx/modules/enterpriseLeads/reducers";
import enterprisePersonnelReducers from "rdx/modules/enterprisePersonnel/reducers";
import enterpriseEarningsReducers from "rdx/modules/enterpriseEarnings/reducers";
import enrollmentReducers from "rdx/modules/enrollment/reducers";
import enterpriseApplicationsReducers from "rdx/modules/enterpriseApplications/reducers";
import buildPartnerApplicationsReducers from "rdx/modules/buildPartnerApplications/reducers";
import complianceIssuesReducers from "rdx/modules/complianceIssues/reducers";
import adminLeadsReducers from "rdx/modules/adminLeads/reducers";
import dashboardPromotionsReducers from "rdx/modules/dashboardPromotions/reducers";
import dashboardUpdatesReducers from "rdx/modules/dashboardUpdates/reducers";
import seatapprovalsReducers from "rdx/modules/seatApprovals/reducers";
import enterpriseProLaborFormReducers from "rdx/modules/enterpriseProLaborForm/reducers";
import notificationsReducers from "rdx/modules/notifications/reducers";
import viewedAssetsReducers from "rdx/modules/viewedAssets/reducers";
import activateAcctBtnClicked from "rdx/modules/activateAcctBtnClicked/reducers";
import preferredpartnersReducers from "rdx/modules/preferredPartners/reducers";
import platformModulesReducers from "rdx/modules/platformModules/reducers";
import roofinProposalsReducers from "rdx/modules/roofing/reducers";
import crmActivityReducers from "rdx/modules/crmActivities/reducers";
import salesTeamsReducers from "rdx/modules/salesTeams/reducers";
import sellerIntegrityReducers from "rdx/modules/sellerIntegrity/reducers";
import publicApiProxyReducers from "rdx/modules/publicApiProxy/reducers";
import projectpresalesReducers from "rdx/modules/projectPreSales/reducers";
import fundingProposalsReducers from "rdx/modules/fundingProposals/reducers";
import fileuploadReducers from "rdx/modules/fileUpload/reducers";
import customFieldsReducers from "rdx/modules/customFields/reducers";
import projectBlockerSlice from "./modules/projectBlockers/slice";
import marketingSlice from "./modules/marketing/slice";
import csvReportsSlice from "./modules/csvReports/slice";
import { api } from "./services/api";
// IMPORT_PT (for script, do not remove)

export const reducers = {
  ...leadsReducers,
  ...activeRequestsReducers,
  ...appReducers,
  ...messagesReducers,
  ...projectsReducers,
  ...inboxReducers,
  ...usersReducers,
  ...teamReducers,
  ...rbacReducers,
  ...adminProjectsReducers,
  ...workflowsReducers,
  ...ambassadorReducers,
  ...profileReducers,
  ...admindashboardReducers,
  ...consultantDashboardReducers,
  ...reportsReducers,
  ...settingsReducers,
  ...adminNoticesReducers,
  ...adminSmsReducers,
  ...earningsReducers,
  ...registrationReducers,
  ...financialsReducers,
  ...messagingMetricsReducers,
  ...adminProposalsReducers,
  ...mentorratingsReducers,
  ...workSchedulesReducers,
  ...bypassPaywallReducers,
  ...careerListingsReducers,
  ...stateLicensesReducers,
  ...authReducers,
  ...filterSetsReducers,
  ...compensationReducers,
  ...platformReducers,
  ...enterpriseProjectsReducers,
  ...enterpriseOrgReducers,
  ...enterpriseLeadsReducers,
  ...enterprisePersonnelReducers,
  ...enterpriseEarningsReducers,
  ...enrollmentReducers,
  ...enterpriseApplicationsReducers,
  ...buildPartnerApplicationsReducers,
  ...complianceIssuesReducers,
  ...adminLeadsReducers,
  ...dashboardPromotionsReducers,
  ...dashboardUpdatesReducers,
  ...seatapprovalsReducers,
  ...enterpriseProLaborFormReducers,
  ...notificationsReducers,
  ...viewedAssetsReducers,
  ...activateAcctBtnClicked,
  ...preferredpartnersReducers,
  ...platformModulesReducers,
  ...roofinProposalsReducers,
  ...crmActivityReducers,
  ...salesTeamsReducers,
  ...sellerIntegrityReducers,
  ...publicApiProxyReducers,
  ...projectpresalesReducers,
  ...fundingProposalsReducers,
  ...fileuploadReducers,
  ...customFieldsReducers,
  // INSERTION_PT (for script, do not remove)
};

const sliceReducers = {
  [marketingSlice.name]: marketingSlice.reducer,
  [projectBlockerSlice.name]: projectBlockerSlice.reducer,
  [csvReportsSlice.name]: csvReportsSlice.reducer,
};

const apiReducers = {
  [api.reducerPath]: api.reducer,
};

export default (history) =>
  combineReducers({
    ...reducers,
    ...sliceReducers,
    ...apiReducers,
    history,
  });
