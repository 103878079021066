import { put, select } from "redux-saga/effects";
import { cloneDeep } from "lodash";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import selectors from "rdx/modules/enterpriseLeads/selectors";
import leadsSelectors from "rdx/modules/leads/selectors";
import platformSelectors from "rdx/modules/platform/selectors";
import entProjectsSelectors from "rdx/modules/enterpriseProjects/selectors";
import { camelizeKeys } from "lib/helpers";
import actions from "rdx/actions";
import messages from "../messages";

function* submitLeadToProvider(action) {
  const { leadId, enterprise, admin, isLeadGen = false } = action.payload;
  if (!leadId) {
    throw new Error(`Invalid lead ID = ${leadId} specified. Please check this parameter.`);
  }
  const { success, data, error } = yield* makeRequest.post(`/provider_leads/${leadId}/qualify`, {
    lead_id: leadId,
    enterprise,
    admin,
  });

  if (success && data) {
    if (isLeadGen && enterprise) {
      const leadGenPreSalesProjects = yield select(entProjectsSelectors.getLeadGenPreSalesProjects);
      const { projects, paging } = cloneDeep(leadGenPreSalesProjects);
      const leadIdx = projects.findIndex((lead) => lead.id.split("_")[0] === data.body.lead.id.toString());

      projects[leadIdx] = {
        ...projects[leadIdx],
        id: data.body.id,
        projectStageName: data.body.stage,
        projectStageMilestoneName: data.body.milestone,
      };

      yield put(actions.setLeadGenPreSalesProjects({ paging, projects }));
    }

    const entLeadsList = yield select(selectors.getEnterpriseLeads);
    const leadsList = yield select(leadsSelectors.getLeads);
    const leadToProposalDrawerVisible = yield select(platformSelectors.selectLeadToProposalDrawerVisible);
    const { leads } = leadsList || [];
    const providerLead = camelizeKeys(data.body);

    const enterpriseLeads = entLeadsList.leads.filter((lead) => lead.id !== leadId);
    const newLeadsList = leads.filter((lead) => lead.id !== leadId);
    if (leadToProposalDrawerVisible) {
      yield put(actions.setLeadToProposalDrawerProjectId({ id: data.body.id }));
    }
    yield put(actions.setEnterpriseLeads({ ...entLeadsList, leads: enterpriseLeads }));
    yield put(actions.setLeads({ ...leadsList, leads: newLeadsList }));
    yield put(actions.setLeadSubmitted());
    yield put(actions.setProjectDetails({ providerLead }));

    if (admin) {
      yield put(actions.setAdminProjectDetails(providerLead));
    }

    yield put(
      actions.newSuccessEvent({
        message: messages.LEAD_SUBMIT_SUCCESS,
        payload: data.body,
      }),
    );
  } else {
    return getErrorActions({ error, message: messages.ERROR_SUBMITTING_LEAD });
  }
  return null;
}

export default submitLeadToProvider;
