import { put, call, take, select } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import types from "rdx/modules/projects/types";
import selectors from "rdx/selectors";
import messages from "../messages";
import pollVisionProposal from "./pollVisionProposal";

function* requestVisionProposal(action) {
  const { id, requestType, proposalNotes, cloneId } = action.payload;
  const { success, error } = yield* makeRequest.post(`/provider_leads/${id}/request_vision_proposal`, {
    request_type: requestType || "over-night",
    proposal_notes: proposalNotes,
    clone_id: cloneId,
  });

  if (!success) {
    if (requestType === "self-request") {
      yield put(actions.setCreateVisionReqSuccessful(false));
    }
    return getErrorActions({
      error,
      message: messages.ERROR_REQUESTING_PROPOSAL,
    });
  }

  const pollSuccess = yield call(pollVisionProposal, { projectId: id });
  if (pollSuccess) {
    yield put(actions.getProviderLeadDetails({ providerLeadId: id }));
    const { payload: projectDetails } = yield take(types.SET_PROJECT_DETAILS); // Wait for project details to be set, to make sure the vision proposal is available
    yield put(actions.getProjectProposals({ id }));

    // Update the project in pre-sales without the need to refresh
    const currentProjects = yield select(selectors.getProjectPreSales);
    if (projectDetails?.providerLead?.visionProposal) {
      yield put(
        actions.setProjectsPreSales({
          ...currentProjects,
          projects: updateProjectPreSale(currentProjects, projectDetails, id),
        }),
      );
    }

    if (!cloneId) {
      yield put(
        actions.newSuccessEvent({
          message: messages.REQUEST_VISION_PROPOSAL_SUCCESS,
          duration: 6000,
        }),
      );
    }
  }

  return null;
}

const updateProjectPreSale = (preSaleProjects, projectDetails, id) => {
  const { upcomingStageNames, projectProgress, visionProposalNonrequestableReason } = projectDetails.providerLead;
  const milestoneDisplayName = projectProgress.at(-1);

  return preSaleProjects.projects.map((project) =>
    project.projectId === id
      ? {
          ...project,
          upcomingStageNames,
          passedStageNames: projectProgress,
          visionProposalNonrequestableReason,
          milestoneDisplayName,
        }
      : project,
  );
};

export default requestVisionProposal;
