import { put, select, delay, take } from "redux-saga/effects";
import actions from "rdx/actions";
import { selectors } from "rdx";
import messages from "../messages";
import types from "../types";

const POLLING_INTERVAL = 2000;
const MAX_POLLING_ATTEMPTS = 30;

// Polls for an updated vision proposal count
function* pollVisionProposal({ projectId }) {
  let attempts = 0;
  yield put(actions.getVisionProposalCount({ id: projectId }));
  const { payload: initialCount } = yield take(types.SET_VISION_PROPOSAL_COUNT) || 0;

  while (attempts < MAX_POLLING_ATTEMPTS) {
    try {
      yield put(actions.getVisionProposalCount({ id: projectId }));
      const currentCount = yield select(selectors.getVisionProposalsCount) || 0;

      if (currentCount > initialCount) {
        return true;
      }

      // Wait before next attempt
      yield delay(POLLING_INTERVAL);
      attempts++;
    } catch (error) {
      yield put(
        actions.newErrorEvent({
          message: messages.ERROR_GETTING_VISION_PROPOSAL,
          error,
        }),
      );
    }
  }

  // Polling timeout error
  yield put(
    actions.newErrorEvent({
      message: messages.VISION_PROPOSAL_TIMEOUT,
      error: { messages: messages.VISION_PROPOSAL_TIMEOUT },
    }),
  );

  return false;
}

export default pollVisionProposal;
