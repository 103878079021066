import { put, select, all } from "redux-saga/effects";
import { cloneDeep } from "lodash";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import selectors from "rdx/modules/projects/selectors";
import leadsSelectors from "rdx/modules/leads/selectors";
import preSalesSelectors from "rdx/modules/projectPreSales/selectors";
import actions from "rdx/actions";
import { keysToSnakeCase, camelizeKeys } from "lib/helpers";

import messages from "../messages";

function* updateLead(action) {
  const { leadId } = action.payload;

  if (!leadId) {
    throw new Error(`Invalid lead ID = ${leadId} specified. Please check this parameter.`);
  }

  // params accepted on the BE
  // lead, address, homeowner_availability, enterprise, admin, projectId
  const params = keysToSnakeCase(action.payload);
  const { success, data, error } = yield* makeRequest.patch(`/leads/${leadId}`, params);

  if (success && data) {
    const currentLeadsState = yield select(leadsSelectors.getLeads);
    const { leads } = currentLeadsState || [];
    const leadDetails = camelizeKeys(data.body);
    const { siteInfo } = leadDetails;
    const newList = leads.map((item) => {
      if (item.id === data.body.id) {
        return leadDetails;
      }
      return item;
    });

    const project = yield select(selectors.getProjectDetails);

    const updatedSiteInfoDetails = {
      utilityBillUrl: siteInfo.utilityBillUrl,
      kwhConsumption: siteInfo.kwhConsumption,
      utilityCompany: siteInfo.utilityCompany,
      roofAge: siteInfo.roofAge,
      roofType: siteInfo.roofType,
      servicePanel: siteInfo.servicePanel,
      mounting: siteInfo.mounting,
      otherDetails: siteInfo.otherDetails,
      hoaName: siteInfo.hoaName,
      hoaPhone: siteInfo.hoaPhone,
      financing: siteInfo.financing,
      workType: siteInfo.workType,
    };

    const updatedProviderLead = {
      ...project,
      providerLead: { ...project.providerLead, ...updatedSiteInfoDetails },
      lead: leadDetails,
    };

    const { projects: presalesProjects, paging: presalesPaging } = yield select(preSalesSelectors.getProjectPreSales);
    const idxOfUpdatedLead = presalesProjects.findIndex((proj) => proj.leadId === parseInt(leadId, 10));
    const updatedPreSales = cloneDeep(presalesProjects);
    updatedPreSales[idxOfUpdatedLead] = {
      ...updatedPreSales[idxOfUpdatedLead],
      leadFirstName: leadDetails.firstName,
      leadLastName: leadDetails.lastName,
      leadEmail: leadDetails.email,
      leadPhone: leadDetails.phone,
      addressCity: leadDetails.address.city,
      addressState: leadDetails.address.state,
      coalescedUpdatedAt: leadDetails.updatedAt,
    };

    yield all([
      put(actions.setLeadDetails(leadDetails)),
      put(actions.setProjectDetails(updatedProviderLead)),
      put(actions.setLeads({ ...currentLeadsState, leads: newList })),
      put(actions.newSuccessEvent({ message: messages.LEAD_UPDATE_SUCCESS })),
      put(
        actions.setProjectsPreSales({
          projects: updatedPreSales,
          paging: presalesPaging,
        }),
      ),
    ]);
  } else {
    if (error.message.includes("Do Not Call")) {
      return getErrorActions({ error, message: messages.ERROR_UPDATING_LEAD_DNC_PHONE });
    }
    return getErrorActions({ error, message: messages.ERROR_UPDATING_LEAD });
  }
  return null;
}

export default updateLead;
