import createReducer from "rdx/utils/createReducer";
import types from "rdx/types";
import { formatCompletedBlocker, removeCoBlockerFromModalList } from "lib/helpers";

const initialState = {
  providerLeadsList: {
    providerLeads: [],
    paging: {
      page_size: 0,
      page_number: 1,
      item_count: 0,
    },
  },
  providerLeadsKanban: {
    providerLeads: [],
  },
  providerLeadsFilters: {
    filters: {},
    sorts: [],
  },
  projectDetails: {
    lead: {},
    providerLead: {
      activityLog: {
        historicalData: [],
        priorityData: [],
      },
      crmActivities: [],
    },
    siteSurveySchedule: {},
    projectCommission: {},
    interestRatesDrawerOpen: false,
    interestRates: {
      powurPreferredLoans: [],
      lowestLoanAmount: [],
      lowestTotalLoanAmount: [],
      lowestMonthlyPayment: [],
    },
    isTpoPricingWorksheetRequested: false,
    opsDropAssetsUrl: null,
  },
  mentors: {
    paging: {
      item_count: 0,
      page_number: 1,
      page_size: 50,
    },
    filters: {},
    users: [],
  },
  powurPairs: {
    paging: {
      item_count: 0,
      page_number: 1,
      page_size: 3,
    },
    filters: {},
    users: [],
  },
  eventCounts: [],
  projectsListAutoComplete: {},
  projectLeadsAutoComplete: [],
  notes: [],
  tags: [],
  assets: [],
  proposals: {
    isCreateVisionReqSuccessful: false,
    goSoloProposals: [],
    lightreachProposals: [],
    visionProposals: [],
    visionProposal: {},
    tpoPricingWorksheets: [],
    tpoPricingWorksheetDetails: {},
  },
  visionProposalCount: null,
  roofingProposals: [],
  roofingProposalDetails: {},
  latestRoofingProposal: {},
  proposalSettings: {},
  qualifyComplete: false,
  projectsCsv: {},
  providerLeadsCsv: {},
  soloRequestModalVisible: false,
  roofingModalVisible: false,
  roofingModalTitle: [],
  roofingNonReqReason: [],
  hoveredProject: [],
  contractTabComponent: "contract",
  contractVersion: null,
  compareContractId: null,
  contractTabVisible: false,
  commissionTabVisible: true,
  dashboardTabVisible: false,
  tabsAsLeadGenerator: false,
  activityLogTabVisible: true,
  contractDetails: {},
  kpi: {},
  fundingProposals: [],
  fundingProposalDetails: {},
  changeRequestVisible: false,
  hideProjectChangeBanner: false,
  contractAsSoldDetails: {
    contract: {},
    backBtnTitle: "",
  },
  projectLookupOptions: [],
  latestIndexRoute: "",
};

export default {
  projects: createReducer(initialState, {
    [types.RESET_PROJECT_DETAILS](state) {
      return {
        ...state,
        projectDetails: initialState.projectDetails,
        mentors: initialState.mentors,
        notes: initialState.notes,
        assets: initialState.assets,
        proposals: initialState.proposals,
      };
    },
    [types.RESET_PROJECTS_LIST](state) {
      return {
        ...state,
        providerLeadsList: initialState.providerLeadsList,
      };
    },
    [types.SET_PROJECT_DETAILS](state, action) {
      return {
        ...state,
        projectDetails: {
          lead: {
            ...state.projectDetails.lead,
            ...action.payload?.lead,
          },
          providerLead: action.payload.providerLead || state.projectDetails.providerLead,
          projectCommission: state.projectDetails.projectCommission,
        },
      };
    },
    [types.SET_LEAD_PROJECT_DETAILS](state, action) {
      return {
        ...state,
        projectDetails: {
          ...state.projectDetails,
          lead: {
            ...state.projectDetails.lead,
            ...action.payload?.lead,
          },
        },
      };
    },
    [types.SET_PROVIDER_LEADS_LIST](state, action) {
      return {
        ...state,
        providerLeadsList: {
          providerLeads: action.payload.providerLeads,
          paging: action.payload.paging,
          kpi: action.payload.kpi,
        },
      };
    },
    [types.SET_PROVIDER_LEADS_LIST_FILTERS](state, action) {
      return {
        ...state,
        providerLeadsFilters: action.payload,
      };
    },
    [types.SET_PROJECTS_AUTOCOMPLETE_SUGGESTIONS](state, action) {
      const { key, suggestions } = action.payload;
      if (key) {
        return {
          ...state,
          projectsListAutoComplete: {
            ...state.projectsListAutoComplete,
            [key]: suggestions || [],
          },
        };
      }
      return state;
    },
    [types.SET_PROJECT_LEADS_AUTOCOMPLETE](state, action) {
      return {
        ...state,
        projectLeadsAutoComplete: action.payload,
      };
    },
    [types.SET_MENTORS_LIST](state, action) {
      return {
        ...state,
        mentors: {
          ...state.mentors,
          ...action.payload,
        },
      };
    },
    [types.SET_MENTOR_FILTERS](state, action) {
      return {
        ...state,
        mentors: {
          ...state.mentors,
          ...action.payload,
        },
      };
    },
    [types.SET_POWUR_PAIR_LIST](state, action) {
      return {
        ...state,
        powurPairs: {
          ...state.powurPairs,
          ...action.payload,
        },
      };
    },
    [types.RESET_MENTORS_LIST](state) {
      return { ...state, mentors: initialState.mentors };
    },
    [types.RESET_POWUR_PAIR_LIST](state) {
      return { ...state, powurPairs: initialState.powurPairs };
    },
    [types.SET_NOTES](state, action) {
      return {
        ...state,
        notes: action.payload.notes,
        tags: action.payload.availableTags || state.tags,
      };
    },
    [types.SET_ASSETS](state, action) {
      return { ...state, assets: action.payload };
    },
    [types.SET_OPS_DROP_ASSETS_URL](state, action) {
      return {
        ...state,
        projectDetails: {
          ...state.projectDetails,
          opsDropAssetsUrl: action.payload,
        },
      };
    },
    [types.SET_PROJECT_PROPOSALS](state, action) {
      return {
        ...state,
        proposals: {
          ...state.proposals,
          ...action.payload,
        },
      };
    },
    [types.SET_CREATE_VISION_REQ_SUCCESS](state, action) {
      return {
        ...state,
        proposals: {
          ...state.proposals,
          isCreateVisionReqSuccessful: action.payload,
        },
      };
    },
    [types.SET_VISION_PROPOSAL_COUNT](state, action) {
      return { ...state, visionProposalCount: action.payload };
    },
    [types.SET_VISION_PROPOSAL_COUNT](state, action) {
      return { ...state, visionProposalCount: action.payload };
    },
    [types.SET_PROJECT_ADDRESS_GEOCODE](state, action) {
      return {
        ...state,
        projectDetails: {
          ...state.projectDetails,
          lead: {
            ...state.projectDetails.lead,
            address: {
              ...state.projectDetails.lead.address,
              lat: action.payload.lat,
              long: action.payload.lng,
            },
          },
        },
      };
    },
    [types.UPDATE_PROJECT_BADGE_COUNT](state, action) {
      return {
        ...state,
        projectDetails: {
          ...state.projectDetails,
          providerLead: {
            ...state.projectDetails.providerLead,
            messageCounters: {
              ...state.projectDetails.providerLead.messageCounters,
              childCount: action.payload.childCount,
              viewedCount: action.payload.viwedCount,
            },
          },
        },
      };
    },
    [types.SET_LEADS_EVENT_COUNTS](state, action) {
      return { ...state, eventCounts: action.payload };
    },
    [types.INVITE_HOMEOWNER](state, action) {
      return { ...state, eventCounts: action.payload };
    },

    [types.SET_SITE_SURVEY_SCHEDULE](state, action) {
      return {
        ...state,
        projectDetails: {
          ...state.projectDetails,
          siteSurveySchedule: action.payload,
        },
      };
    },
    [types.SET_PROPOSAL_SETTINGS](state, action) {
      return {
        ...state,
        proposalSettings: action.payload,
      };
    },
    [types.SET_PROJECT_PAY_TERMS](state, action) {
      return {
        ...state,
        projectDetails: {
          ...state.projectDetails,
          projectCommission: action.payload || {},
        },
      };
    },

    [types.SET_ROOFING_PROPOSALS](state, action) {
      return { ...state, roofingProposals: action.payload };
    },
    [types.SET_LATEST_ROOFING_PROPOSAL](state, action) {
      return { ...state, latestRoofingProposal: action.payload };
    },
    [types.SET_PROJECT_ROOFING_PROPOSAL_DETAILS](state, action) {
      return {
        ...state,
        roofingProposalDetails: action.payload,
      };
    },

    [types.SET_INTEREST_RATES_DRAWER_OPEN](state) {
      return {
        ...state,
        projectDetails: {
          ...state.projectDetails,
          interestRatesDrawerOpen: true,
        },
      };
    },
    [types.SET_INTEREST_RATES_DRAWER_CLOSED](state) {
      return {
        ...state,
        projectDetails: {
          ...state.projectDetails,
          interestRatesDrawerOpen: false,
          interestRates: initialState.projectDetails.interestRates,
        },
      };
    },
    [types.SET_PROJECT_INTEREST_RATES](state, action) {
      return {
        ...state,
        projectDetails: {
          ...state.projectDetails,
          interestRates: {
            powurPreferredLoans: action.payload.powurPreferredLoans || [],
            lowestLoanAmount: action.payload.lowestLoanAmount || [],
            lowestMonthlyPayment: action.payload.lowestMonthlyPayment || [],
            lowestTotalLoanAmount: action.payload.lowestLoanPayment || [],
          },
        },
      };
    },
    [types.INVITE_TO_POWUR_SITE](state, action) {
      return { ...state, eventCounts: action.payload };
    },
    [types.SET_PROJECTS_CSV](state, action) {
      return {
        ...state,
        projectsCsv: action.payload,
      };
    },
    [types.SET_PROVIDER_LEADS_CSV](state, action) {
      return {
        ...state,
        providerLeadsCsv: action.payload,
      };
    },
    [types.SET_REQUEST_MODAL_VISIBLE](state, action) {
      return {
        ...state,
        soloRequestModalVisible: action.payload,
      };
    },
    [types.SET_ROOFING_MODAL_VISIBLE](state, action) {
      return {
        ...state,
        roofingModalVisible: action.payload,
      };
    },
    [types.SET_ROOFING_MODAL_TITLE](state, action) {
      return {
        ...state,
        roofingModalTitle: action.payload,
      };
    },
    [types.SET_ROOFING_NON_REQ_REASON](state, action) {
      return {
        ...state,
        roofingNonReqReason: action.payload,
      };
    },
    [types.SET_HOVERED_PROJECT](state, action) {
      return {
        ...state,
        hoveredProject: action.payload,
      };
    },
    [types.SET_PROVIDER_LEADS_KANBAN](state, action) {
      return {
        ...state,
        providerLeadsKanban: {
          providerLeads: action.payload.providerLeads,
          kpi: action.payload.kpi,
        },
      };
    },
    [types.SET_PROVIDER_LEAD_STAGE_IN_KANBAN](state, action) {
      const { providerLead } = action.payload;
      const updatedKanban = [...state.providerLeadsKanban.providerLeads];
      const providerLeadIdx = updatedKanban.findIndex((pl) => pl.id === providerLead.id);
      updatedKanban[providerLeadIdx].stage = providerLead.stage;
      return {
        ...state,
        providerLeadsKanban: {
          providerLeads: updatedKanban,
        },
      };
    },
    [types.SET_CRM_ACTIVITY_IN_LIST](state, action) {
      const { projectIdx, activityIdx, updatedActivity } = action.payload;
      const updatedKanban = [...state.providerLeadsKanban.providerLeads];
      updatedKanban[projectIdx].activities[activityIdx] = updatedActivity;
      return {
        ...state,
        providerLeadsKanban: {
          providerLeads: updatedKanban,
        },
      };
    },
    [types.ADD_CRM_ACTIVITY_TO_KANBAN](state, action) {
      const { projectIdx, activity } = action.payload;
      const updatedKanban = [...state.providerLeadsKanban.providerLeads];
      updatedKanban[projectIdx].activities.push(activity);
      return {
        ...state,
        providerLeadsKanban: {
          providerLeads: updatedKanban,
        },
      };
    },
    [types.ADD_CRM_ACTIVITY_TO_LIST](state, action) {
      const { projectIdx, activity } = action.payload;
      const updatedList = [...state.providerLeadsList.providerLeads];
      updatedList[projectIdx].activities.push(activity);
      return {
        ...state,
        providerLeadsList: {
          ...state.providerLeadsList,
          providerLeads: updatedList,
        },
      };
    },
    [types.REMOVE_COMPLETE_CRM_ACTIVITIES](state, action) {
      const { leadId } = action.payload;
      if (state.providerLeadsKanban.providerLeads.length) {
        const projectIdx = state.providerLeadsKanban.providerLeads.findIndex(
          (project) => project.homeowner.id === leadId,
        );

        const updatedKanban = [...state.providerLeadsKanban.providerLeads];
        const updatedActivities = updatedKanban[projectIdx].activities.filter(
          (activity) => !activity.completeType && !activity.archivedAt,
        );

        updatedKanban[projectIdx].activities = updatedActivities;
        return {
          ...state,
          providerLeadsKanban: {
            providerLeads: updatedKanban,
          },
        };
      }
      return {
        ...state,
      };
    },
    [types.SET_CRM_ACTIVITY_IN_DETAIL](state, action) {
      const isPriority = !action.payload.completeType;
      return {
        ...state,
        projectDetails: {
          ...state.projectDetails,
          providerLead: {
            ...state.projectDetails.providerLead,
            activityLog: {
              historicalData: [
                ...(isPriority ? [] : [action.payload]),
                ...state.projectDetails.providerLead.activityLog.historicalData,
              ],
              priorityData: [
                ...state.projectDetails.providerLead.activityLog.priorityData,
                ...(isPriority ? [action.payload] : []),
              ],
            },
          },
        },
      };
    },
    [types.ADD_PROJECT_CRM_ACTIVITY](state, action) {
      return {
        ...state,
        projectDetails: {
          ...state.projectDetails,
          providerLead: {
            ...state.projectDetails.providerLead,
            crmActivities: [...state.projectDetails.providerLead.crmActivities, action.payload],
          },
        },
      };
    },
    [types.UPDATE_PROJECT_CRM_ACTIVITY](state, action) {
      const activityId = action.payload.id;
      return {
        ...state,
        projectDetails: {
          ...state.projectDetails,
          providerLead: {
            ...state.projectDetails.providerLead,
            crmActivities: state?.projectDetails?.providerLead?.crmActivities?.map((activity) =>
              activity.id === activityId ? action.payload : activity,
            ),
          },
        },
      };
    },
    [types.UPDATE_PROJECT_CRM_ACTIVITIES](state, action) {
      return {
        ...state,
        projectDetails: {
          ...state.projectDetails,
          providerLead: {
            ...state.projectDetails.providerLead,
            crmActivities: action.payload,
          },
        },
      };
    },
    [types.UPDATE_CRM_ACTIVITY_IN_DETAIL](state, action) {
      const { activityIdx, activityLogList, logActivity } = action.payload;
      const updatedActivityLog = { ...state.projectDetails.providerLead.activityLog };
      updatedActivityLog[activityLogList][activityIdx] = logActivity;
      return {
        ...state,
        projectDetails: {
          ...state.projectDetails,
          providerLead: {
            ...state.projectDetails.providerLead,
            activityLog: updatedActivityLog,
          },
        },
      };
    },
    [types.SET_CRM_ACTIVITY_LOG](state, action) {
      return {
        ...state,
        projectDetails: {
          ...state.projectDetails,
          providerLead: {
            ...state.projectDetails.providerLead,
            activityLog: action.payload,
          },
        },
      };
    },
    [types.SET_CONTRACT_TAB_COMPONENT](state, action) {
      return {
        ...state,
        contractTabComponent: action.payload,
      };
    },

    [types.SET_CONTRACT_DETAILS](state, action) {
      return {
        ...state,
        contractDetails: action.payload,
      };
    },

    [types.SET_CONTRACT_TAB_VISIBLE](state, action) {
      return {
        ...state,
        contractTabVisible: action.payload,
      };
    },

    [types.SET_DASHBOARD_TAB_VISIBLE](state, action) {
      return {
        ...state,
        dashboardTabVisible: action.payload,
        activityLogTabVisible: !action.payload,
      };
    },

    [types.SET_TABS_AS_LEAD_GENERATOR](state, action) {
      return {
        ...state,
        tabsAsLeadGenerator: action.payload,
      };
    },

    [types.SET_KPI_DATA](state, action) {
      return {
        ...state,
        kpi: action.payload,
      };
    },
    [types.SET_COMMISSION_TAB_VISIBLE](state, action) {
      return {
        ...state,
        commissionTabVisible: action.payload,
      };
    },

    [types.SET_FUNDING_PROPOSALS](state, action) {
      return {
        ...state,
        fundingProposals: action.payload,
      };
    },
    [types.SET_PROJECT_FUNDING_PROPOSAL_DETAILS](state, action) {
      return {
        ...state,
        fundingProposalDetails: action.payload,
      };
    },
    [types.CLEAR_PROJECT_FUNDING_PROPOSAL_DETAILS](state) {
      return {
        ...state,
        fundingProposalDetails: initialState.fundingProposalDetails,
      };
    },
    [types.SET_FUNDING_PROPOSAL_REQUESTABLE](state, action) {
      return {
        ...state,
        projectDetails: {
          ...state.projectDetails,
          providerLead: {
            ...state.projectDetails.providerLead,
            ...action.payload,
          },
        },
      };
    },

    [types.SET_PROJECT_CHANGE_REQUEST_VISIBLE](state, action) {
      return {
        ...state,
        changeRequestVisible: action.payload,
      };
    },

    [types.SET_CONTRACT_AS_SOLD_DETAILS](state, action) {
      return {
        ...state,
        contractAsSoldDetails: {
          ...state.contractAsSoldDetails,
          ...action.payload,
        },
      };
    },

    [types.SET_PROJECT_LOOKUP_OPTIONS](state, action) {
      return {
        ...state,
        projectLookupOptions: action.payload,
      };
    },
    [types.SET_LATEST_INDEX_ROUTE](state, action) {
      return {
        ...state,
        latestIndexRoute: action.payload,
      };
    },

    [types.SET_COMPARE_CONTRACT_ID](state, action) {
      return {
        ...state,
        compareContractId: action.payload,
      };
    },

    [types.SET_CONTRACT_VERSION](state, action) {
      return {
        ...state,
        contractVersion: action.payload,
      };
    },

    [types.SET_HIDE_PROJECT_CHANGE_BANNER](state, action) {
      return {
        ...state,
        hideProjectChangeBanner: action.payload,
      };
    },

    [types.SET_TPO_PRICING_WORKSHEETS](state, action) {
      return {
        ...state,
        proposals: {
          ...state.proposals,
          ...action.payload,
        },
      };
    },
    [types.SET_TPO_PRICING_WORKSHEET](state, action) {
      return {
        ...state,
        proposals: {
          ...state.proposals,
          tpoPricingWorksheetDetails: action.payload,
        },
      };
    },
    [types.SET_IS_TPO_PRICING_WORKSHEET_REQUESTED](state, action) {
      return {
        ...state,
        projectDetails: {
          ...state.projectDetails,
          isTpoPricingWorksheetRequested: action.payload,
        },
      };
    },
    [types.SET_CHANGE_ORDER_BLOCKER_COMPLETE](state) {
      if (state.projectDetails.providerLead.crmActivities.length) {
        const { updatedCrmActivities, updatedHistoricalActivityLog } = formatCompletedBlocker(
          state.projectDetails.providerLead.crmActivities,
          state.projectDetails.providerLead.activityLog.historicalData,
        );

        return {
          ...state,
          projectDetails: {
            ...state.projectDetails,
            providerLead: {
              ...state.projectDetails.providerLead,
              crmActivities: updatedCrmActivities,
              activityLog: {
                ...state.projectDetails.activityLog,
                historicalData: updatedHistoricalActivityLog,
              },
            },
          },
        };
      }
      return { ...state };
    },
    [types.REMOVE_CO_BLOCKER_FROM_MODAL](state) {
      if (state.projectDetails.providerLead.crmActivities.length) {
        const { updatedCrmActivities } = removeCoBlockerFromModalList(state.projectDetails.providerLead.crmActivities);

        return {
          ...state,
          projectDetails: {
            ...state.projectDetails,
            providerLead: {
              ...state.projectDetails.providerLead,
              crmActivities: updatedCrmActivities,
            },
          },
        };
      }
      return { ...state };
    },
    // INSERTION_PT (for script, do not remove)
  }),
};
